// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("/opt/build/repo/src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-flowerbeauty-index-tsx": () => import("/opt/build/repo/src/pages/flowerbeauty/index.tsx" /* webpackChunkName: "component---src-pages-flowerbeauty-index-tsx" */),
  "component---src-pages-flowereyewear-about-tsx": () => import("/opt/build/repo/src/pages/flowereyewear/about.tsx" /* webpackChunkName: "component---src-pages-flowereyewear-about-tsx" */),
  "component---src-pages-flowereyewear-blog-post-tsx": () => import("/opt/build/repo/src/pages/flowereyewear/blog-post.tsx" /* webpackChunkName: "component---src-pages-flowereyewear-blog-post-tsx" */),
  "component---src-pages-flowereyewear-index-tsx": () => import("/opt/build/repo/src/pages/flowereyewear/index.tsx" /* webpackChunkName: "component---src-pages-flowereyewear-index-tsx" */),
  "component---src-pages-flowerhome-about-tsx": () => import("/opt/build/repo/src/pages/flowerhome/about.tsx" /* webpackChunkName: "component---src-pages-flowerhome-about-tsx" */),
  "component---src-pages-flowerhome-collections-tsx": () => import("/opt/build/repo/src/pages/flowerhome/collections.tsx" /* webpackChunkName: "component---src-pages-flowerhome-collections-tsx" */),
  "component---src-pages-flowerhome-kids-tsx": () => import("/opt/build/repo/src/pages/flowerhome/kids.tsx" /* webpackChunkName: "component---src-pages-flowerhome-kids-tsx" */),
  "component---src-pages-flowerpress-dear-drew-homefry-tsx": () => import("/opt/build/repo/src/pages/flowerpress/dear-drew/homefry.tsx" /* webpackChunkName: "component---src-pages-flowerpress-dear-drew-homefry-tsx" */),
  "component---src-pages-flowerpress-drews-pick-tsx": () => import("/opt/build/repo/src/pages/flowerpress/drews-pick.tsx" /* webpackChunkName: "component---src-pages-flowerpress-drews-pick-tsx" */),
  "component---src-pages-flowerpress-index-tsx": () => import("/opt/build/repo/src/pages/flowerpress/index.tsx" /* webpackChunkName: "component---src-pages-flowerpress-index-tsx" */),
  "component---src-pages-flowerpress-recipes-tsx": () => import("/opt/build/repo/src/pages/flowerpress/recipes.tsx" /* webpackChunkName: "component---src-pages-flowerpress-recipes-tsx" */),
  "component---src-pages-flowerpress-video-tsx": () => import("/opt/build/repo/src/pages/flowerpress/video.tsx" /* webpackChunkName: "component---src-pages-flowerpress-video-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("/opt/build/repo/src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-terms-tsx": () => import("/opt/build/repo/src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

