import { css } from '@emotion/core'

import FlowerCursor from '@/images/flower-cursor.png'
import FlowerInactiveCursor from '@/images/flower-inactive-cursor.png'
import FlowerMenuCursor from '@/images/menu-cursor.png'

export const FlowerCursorActive = css`
  cursor: url(${FlowerCursor}) 25 25, pointer;
`

export const FlowerCursorInActive = css`
  cursor: url(${FlowerInactiveCursor}) 25 25, auto;
`

export const FlowerCursorMenu = css`
  cursor: url(${FlowerMenuCursor}) 25 25, auto;
`