import React from 'react'
import Helmet from 'react-helmet'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { Provider } from 'react-redux'
import { compose, withProps } from 'recompose'

import store from '@/store'
import { GlobalStyles, theme } from '@/style'

class App extends React.PureComponent<object, State> {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Helmet
            title={'FlowerByDrew'}
            meta={[
              {
                name: 'viewport',
                content: 'initial-scale=1, viewport-fit=cover',
              },
              {
                name: 'description',
                content:
                  'Drew Barrymore’s online destination for all things Flower. Here you’ll find the latest from Flower Beauty, Flower Home, Flower Eyewear, and Drew’s Flower Press.',
              },
              {
                name: 'keywords',
                content:
                  'Drew Barrymore, flower by drew, flower by drew barrymore, flower home, flower beauty, flower press, flower eyewear, flower films, dear drew, barrymore wine',
              },
              {
                name: 'google-site-verification',
                content: '92PhPjRPftfweX6ilRu4rbPxcq03veIZMemHpS0EtwE',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Global styles={GlobalStyles} />
          {this.props.children}
        </ThemeProvider>
      </Provider>
    )
  }
}

const debug = withProps(console.log)
const enhance = compose(debug)
export default process.env.DEBUG ? enhance(App) : App 


