import { combineReducers } from 'redux'
import { createAction } from 'redux-actions'
import { createSelector } from 'reselect'
import invariant from 'invariant'
import {
  chain,
  identity,
  isArray,
  isEmpty,
  isFunction,
  isNil,
  isPlainObject,
  isString,
  get,
  first,
  mapValues,
} from 'lodash'

/**
 * Async utils for Redux:
 *
 * The way this works is that instead of independent actions,
 * we dispatch coupled actions for Start + Success or Start + Failure.
 *
 * It's a common pattern to request an asynchronous action and wait for
 * the response. Rather than creating multiple action type and generators,
 * we abstract it under a single action with subtypes:
 * - type/STARTED
 * - type/SUCCEEDED
 * - type/FAILED
 * - type/CANCELLED
 *
 * Even though FSA recommends using a single type for request + response and
 * discriminate via error/payload, we found that debugging is harder when
 * we don't discriminate via type name.
 *
 * The action creator helps creating a container for all necessary action creators:
 *
 * - typeCreator.started(payload)
 * - typeCreator.succeeded(req, payload)
 * - etc.
 *
 * It's important to note that the response action creators take the request payload
 * as their first argument. And by that, we mean and object of the shape:
 * { payload, meta }
 * The response action creators handle setting of the meta data automatically based
 * on the request provided.
 *
 * The action objects look as follows:
 *
 * # Started
 * {
 *   meta: {
 *     async: true
 *     status: STARTED
 *     identifier: "some-identifier"
 *   },
 *   {
 *     payload: ...
 *   }
 * }
 *
 * # Response
 * {
 *   meta: {
 *     async: true
 *     status: SUCCEEDED|FAILED
 *     identifier: "some-identifier"
 *   },
 *   {
 *     payload: ...
 *   }
 * }
 *
 * This allows us to track what requests are currently loading via the `loadingReducer`.
 * Every async request is tracked and marked as complete. It also allows to track if there's
 * _any_ request (independent of identifier) active right now.
 *
 * Further custom reducers can be implemented on top of this, or example centralized error
 * handling, logging etc.
 *
 * ## Identifier
 * When creating an action creator, one has the option of specifying how the identifier should
 * be created when the request is dispatched. This creator is of the shape: `(payload) => {string}`
 * and by default encodes all arguments into a JSON and returns the stringified version.
 * We assume that the request is purely based on this payload, but the user has the option
 * to override that identifier creator to use anything as the identifier.
 *
 * In your application reducer, you can track the last requested identifier to make sure
 * that the response you receive is the one that matches the identifier.
 *
 * Note: this is completely independent of any side effects class you're using and simply
 * provides a way to cleanly handle the creation of actions and tracking the current loading
 * status.s
 */

export const TYPE_SUFFIX_STARTED = 'STARTED'
export const TYPE_SUFFIX_SUCCEEDED = 'SUCCEEDED'
export const TYPE_SUFFIX_FAILED = 'FAILED'
export const TYPE_SUFFIX_CANCELLED = 'CANCELLED'

/**
 * @func asyncMiddleware
 *
 * Async middleware is a small middleware that helps keeping track of async
 * requests created through action creators. Every action creator contains a
 * promise, this middleware makes sure it resolves/rejects at the appropriate moment.
 *
 * Also, when a request is passed into dispatch, it returns the promise for the caller
 * to subscribe or await directly, like this:
 *
 * ```
 * test = async (restaurantId) => {
 *   try {
 *     const content = await this.props.dispatch(
 *       orderActions.requestRestaurantWithMenu.request({ restaurantId })
 *     )
 *     console.log('Success.', content)
 *   } catch (error) {
 *     console.log('Eerrrr.', error)
 *   }
 * }
 * ```
 */
export const asyncMiddleware = () => next => action => {
  // Not an async action, don't care.
  if (!action.meta || !action.meta.async) {
    return next(action)
  }

  const status = action.meta.status
  if (status === TYPE_SUFFIX_SUCCEEDED && action.meta.resolve) {
    // If we have a resolve on the meta (passed down from the request)
    // then we call it here after a successful request.
    action.meta.resolve(action.payload)
  } else if (status === TYPE_SUFFIX_FAILED && action.meta.reject) {
    // if we have a reject on the meta (passed down from the request)
    // then we call it here after a failed request.
    action.meta.reject(action.payload)
  } else if (status === TYPE_SUFFIX_STARTED && action.meta.promise) {
    // If the status is started, the action was dispatched via "request()"
    // In this case, we return the promise through dispatch for the caller to subscribe
    // directly instead of having to go through the hoops.
    next(action)
    return action.meta.promise
  }

  return next(action)
}

/** @func defaultIdentifierCreator
 *  @param {vararg}
 *
 *  The default identifier creator creates a string from the arguments (payload)
 *  it receives, which acts as the identifier for a dispatched action.
 *
 *  e.g.: someAction({ foo: "bar" }) would create an identifier of "{foo:"bar"}"
 */
export const defaultIdentifierCreator = (...args) => {
  if (isPlainObject(args)) {
    return JSON.stringify(...args)
  }

  return ''
}

/** @func createAsyncActions
 *  @param {string} type
 *  @param {func|func[]} requestCreator
 *  @param {func|func[]} responseCreator
 *  @param {func} identifierCreator
 *
 *  @returns {Object.<string, func>}
 *
 *  Creates an abstraction of a single action type for async operations that
 *  expect to be in the call/response model.
 *  The object has four distinct action creators attached:
 *
 *  - started(payload): Used when the request is dispatched initially. Type: {type}/STARTED
 *  - succeeded(payload): Used when the request has succeeded. Type: {type}/SUCCEEDED
 *  - failed(payload): Used when the request has failed: Type: {type}/FAILED
 *  - cancelled(payload): Used to cancel the request: Type: {type}/CANCELLED
 *
 *  - toString() returns the original type without suffix.
 *
 *  TODO: Cancel has not been implemented yet.
 */
export const createAsyncActions = function(
  type,
  requestCreator,
  responseCreator,
  identifierCreator
) {
  let resolve = null
  let reject = null

  const promise = new Promise(function(promiseResolve, promiseRejct) {
    resolve = promiseResolve
    reject = promiseRejct
  }).catch(error => console.log(`Error: ${error.message}`))

  // The identifier creator creates an
  // identifier from the input to identify the request.
  const finalIdentifierCreator = isNil(identifierCreator)
    ? defaultIdentifierCreator
    : identifierCreator

  // The meta creator is used to set the identifier
  // in the meta field. The passed meta creator
  // can override that identifier.
  const finalRequestMetaCreator = ({ resolve, reject }) =>
    function(...args) {
      let meta = {
        resolve,
        reject,
        promise,
        identifier: finalIdentifierCreator(...args),
        async: true,
        status: TYPE_SUFFIX_STARTED,
        baseType: type,
      }

      if (isArray(requestCreator)) {
        let [, metaCreator] = requestCreator
        if (isFunction(metaCreator)) {
          meta = { ...meta, ...metaCreator(...args) }
        }
      }

      return meta
    }

  // Separating the payload creator for the request.
  const finalRequestPayloadCreator =
    isArray(requestCreator) && requestCreator.length > 0 ? requestCreator[0] : requestCreator

  // Splitting response creators into payload and meta.
  let responsePayloadCreator = isFunction(responseCreator) ? responseCreator : identity
  let responseMetaCreator = null
  if (isArray(responseCreator)) {
    responsePayloadCreator = get(responseCreator, '[0]')
    responseMetaCreator = get(responseCreator, '[1]')
  }

  const finalResponsePayloadCreator = (req, ...args) => {
    return responsePayloadCreator(...args)
  }

  const finalResponseMetaCreator = status => (req, ...args) => {
    invariant(
      isPlainObject(req) && isPlainObject(req.meta) && req.meta.async === true,
      `Expected to respond to async request. First parameter should be the request ({ payload, meta })`
    )

    let meta = {
      identifier: req.meta.identifier,
      async: true,
      baseType: type,
      status: status,
      resolve: req.meta.resolve,
      reject: req.meta.reject,
    }
    if (isFunction(responseMetaCreator)) {
      meta = { ...meta, ...responseMetaCreator(...args) }
    }

    return meta
  }

  // The response meta creator requires that the responses
  // receive the request object as the first parameter.
  return {
    request: createAction(
      `${type}/${TYPE_SUFFIX_STARTED}`,
      finalRequestPayloadCreator,
      finalRequestMetaCreator({
        resolve: resolve,
        reject: reject,
        promise,
      })
    ),
    succeeded: createAction(
      `${type}/${TYPE_SUFFIX_SUCCEEDED}`,
      finalResponsePayloadCreator,
      finalResponseMetaCreator(TYPE_SUFFIX_SUCCEEDED)
    ),
    failed: createAction(
      `${type}/${TYPE_SUFFIX_FAILED}`,
      finalResponsePayloadCreator,
      finalResponseMetaCreator(TYPE_SUFFIX_FAILED)
    ),
    cancelled: createAction(`${type}/${TYPE_SUFFIX_CANCELLED}`),
    response: promise,
    toString: () => type,
  }
}

/** @func loadingSelectorCreator
 *
 *  Works in tandem with the `loadingReducer` reducer.
 *
 *  Creates a selector that checks if a set of actions is
 *  currently "in progress", i.e. a type/STARTED has been dispatched
 *  that has not yet received a corresponding type/SUCCEEDED,
 *  type/FAILED or type/CANCELLED response.
 *
 *  Multiple actions can be used to check if any of the actions
 *  are still in progress, i.e. loadingSelectorCreator(['SOME_TYPE', 'ANOTHER'])
 *  If you use action creators, make sure to use the base type:
 *  loadingSelectorCreator([types.someAction]) i.e. not types.someAction.started etc.
 *
 *  You can also pass a array of arrays as actions, in which case the 2nd element of each
 *  element is treated as a closure to select the identifier from the global state.
 *  It's recommended that you create these loading selectors centrally as not to
 *  pollute your components and keep the store data structure private.
 */
export const loadingSelectorCreator = actions => (state, props) => {
  // Actions can be an array of strings or an array of tuples with a key
  // and a selector for the identifier matcher from the state:
  // actions = ['SOME_TYPE'] or [someType]
  // actions = [[someType, (state, props) => state.someIdentifier]]

  return chain(actions)
    .some(action => {
      if (isString(action) || isPlainObject(action)) {
        const actionKey = action.toString()
        return isPlainObject(state[actionKey]) ? !isEmpty(state[actionKey]) : state[actionKey]
      }

      const [, identifierCreator] = action
      const loadingStatuses = state[action]

      if (isNil(loadingStatuses)) {
        return false
      }

      const identifier = identifierCreator(state, props)
      return loadingStatuses[identifier]
    })
    .value()
}

/** @func loadingReducer
 *
 *  The loading reducer acts as a global reducer and tracks every
 *  dispatched async action.
 *
 *  As "requests" (type/STARTED) actions wait for the "response",
 *  the reducer keeps track of the unfinished actions.
 *  Async actions are mainly intended to be used with a side
 *  effect framework like Sagas, Thunks or Rx for which a common
 *  pattern is the "Request" › "Loading" › "Response" model.
 *
 *  While request and response are tracked in specific reducers,
 *  loading can be centrally tracked for each request. The model
 *  looks like this:
 *
 *  mountpoint: {
 *    SOME/ACTION_TYPE: {
 *      identifier_a: true
 *    },
 *    ANOTHER/FUN_TYPE: {
 *      identifier_b: true,
 *      identifier_c: true
 *    },
 *    AN/EMPTY_TYPE: {}
 *  }
 *
 *  The idea is that we can use the base type of our async actions
 *  to check if that action is in a loading state. And if needed,
 *  pass in an identifier to match against.
 *  A corresponding selector creator `loadingSelectorCreator`
 *  is used to create a selector for a set of specific action types
 *  and identifier selectors.
 *
 *  Once the reducer receives a /SUCCEEDED or /FAILED or /CANCELLED
 *  action, the request is removed from the list.
 *
 *  The selector is responsible for the logic determining if an
 *  action is in progress based on the state, but the basic idea
 *  is that if there are requests set to true in the state, the
 *  request is still loading.
 *
 *  This makes async debugging a lot easier as you can basically
 *  pin the loading state.
 */
export const loadingReducer = (state = {}, action) => {
  const { meta } = action
  if (isNil(meta) || meta.async !== true) {
    return state
  }

  // At this point, we know this is an async request.
  const { baseType, status, identifier } = meta

  if (isNil(identifier) || isEmpty(identifier)) {
    // If the identifier is null, we only track the action
    return {
      ...state,
      [baseType]: status === TYPE_SUFFIX_STARTED,
    }
  } else {
    // Track the request with the identifier. Unset when the
    // request is finished.
    let statuses = isPlainObject(state[baseType]) ? { ...state[baseType] } : {}
    if (status === TYPE_SUFFIX_STARTED) {
      statuses[identifier] = true
    } else {
      delete statuses[identifier]
    }

    return {
      ...state,
      [baseType]: statuses,
    }
  }
}

/**
 * @func createLastErrorSelector
 *
 * Creates a selector for the last error for a set of actions.
 *
 * @param {string[]} actions
 */
export const lastErrorSelectorCreator = actions => state => {
  // Actions can be an array of strings or an array of tuples with a key
  // and a selector for the identifier matcher from the state:
  // actions = ['SOME_TYPE'] or [someType]

  const errors = actions.reduce((acc, action) => {
    if (isString(action) || isPlainObject(action)) {
      const actionKey = action.toString()
      if (state[actionKey] && state[actionKey].error) {
        acc.push(state[actionKey])
      }
    }

    return acc
  }, [])

  if (errors.length > 0) {
    return first(errors)
  } else {
    return null
  }
}

/**
 * @func lastErrorReducer
 *
 * The last error reducer works in a similar fashion to the loadingReducer.
 * We track each async action as it dispatches a `/FAILED` response and
 * store the error.
 *
 * We only use the last error for a specific action because otherwise we'd
 * end up piling on errors.
 * If you need to track errors by request instead of action, you'll want to
 * store them in a separate reducer. More often than not, it's enough to
 * check if a specific request is still loading and the last error is null,
 * as loading will be set to false for a specific request and the error would
 * be true. We also unset the lastError when a request succeeds, but not
 * when it starts.
 *
 * @param {*} state
 * @param {*} action
 */
export const lastErrorReducer = (state = {}, action) => {
  const { meta } = action
  if (isNil(meta) || meta.async !== true) {
    return state
  }

  // At this point, we know this is an async request.
  const { baseType, status } = meta

  // Check if need to update the state. Only when failed or succeeded.
  let newError = state[baseType]
  if (status === TYPE_SUFFIX_FAILED) {
    newError = action.payload
  } else if (status === TYPE_SUFFIX_SUCCEEDED) {
    newError = null
  } else {
    return state
  }

  const error = newError
    ? {
        message: newError.message,
        error: newError,
      }
    : null

  return {
    ...state,
    [baseType]: {
      ...error,
    },
  }
}

// Combine async reducers into a sigle namespace
export const asyncReducer = combineReducers({
  loading: loadingReducer,
  errors: lastErrorReducer,
})

/**
 * @function mountSelectors
 *
 * Utility function that takes an object of selectors and re-selects all of them by
 * selecting the mount point first.
 * This allows us to have selectors side by side with their reducers
 * and then assign a mount point for the selectors.
 *
 * For example, a UI selector could operate on the "local" data structure
 * under the "ui" mount point. A selector on "state.isKeyboardVisible" would
 * then ask for "state.ui.isKeyboardVisible".
 *
 * @param {*} mountPointSelector
 * @param {*} selectors
 */
export const mountSelectors = (mountPointSelector, selectors) => {
  return mapValues(selectors, value =>
    createSelector(
      mountPointSelector,
      value
    )
  )
}

/**
 * @function mountSelectorCreators
 *
 * Utility function that takes an object of selectorCreators and reselects all of them
 * to a mount point. Works similar to `mountSelectors` but allows function arguments.
 *
 * @param {*} mountPointSelector
 * @param {*} selectorCreators
 */
export const mountSelectorCreators = (mountPointSelector, selectorCreators) => {
  return mapValues(selectorCreators, fn => (...params) =>
    createSelector(
      mountPointSelector,
      fn(...params)
    )
  )
}

/**
 * @function loadingSelector
 * Used to select the 'loading' state.
 */
export const loadingSelector = state => state.async.loading

/**
 * @function lastErrorSelector
 * Used to select the 'lastError' state.
 */
export const lastErrorSelector = state => state.async.errors

/**
 * @function createLoadingSelector
 *
 * Utility function that creates a selector that checks if one or multiple actions are
 * currently in progress.
 *
 * @param {string[]|string[][]} actions An array of actions to filter for.
 *    Can be a simple array like: ['ACTION_ONE', 'ACTION_TWO'] or an array
 *    of arrays with identifier selectors: [['ACTION_ONE', (state) => state.identifier]]
 *    in which case the identifier of a request is used to check if a
 *    request is still loading. If the identifier selector is not provided,
 *    the selector simply checks if there are any requests present for a certain action.
 */
export const createLoadingSelector = actions =>
  createSelector(
    loadingSelector,
    loadingSelectorCreator(actions)
  )

/**
 * @function createLastErrorSelector
 *
 * Utility function that creates a selector that selects the last error for a set
 * of actions. Returns only the first error encountered.
 *
 * @param {string[]} actions
 */
export const createLastErrorSelector = actions =>
  createSelector(
    lastErrorSelector,
    lastErrorSelectorCreator(actions)
  )
