const fontFaces = {
  body: 'Apercu-Regular',
  headings: 'Metric-Bold',
  link: 'Metric-Bold',
  largeBody: 'Metric-Bold',
  smallFooter: 'Metric-Bold',
  unique: 'Metric-Bold',
}

const breakpoints = ['40em', '52em', '64em', '80em']
const breakpointsMap = ['xs', 'sm', 'md', 'lg', 'xl']
const mq = {
  [breakpointsMap[0]]: `screen`,
  [breakpointsMap[1]]: `(min-width: ${breakpoints[0]})`,
  [breakpointsMap[2]]: `(min-width: ${breakpoints[1]})`,
  [breakpointsMap[3]]: `(min-width: ${breakpoints[2]})`,
  [breakpointsMap[4]]: `(min-width: ${breakpoints[3]})`,
}

const containerWidths = ['100%', '40em', '52em', '64em', '80em']

const navHeight = ['6rem', '10rem', '10rem', '10rem', '10rem']

const headerSizes = [
  [13, 4, 5, 5, 5],
  [2, 3, 4, 4, 4],
  [2, 2, 3, 3, 3],
  [1, 1, 2, 2, 2],
  [1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1],
]

const colors = {
  black: '#000000',
  primary: '#FF8F9C',
  secondary: '#FFFFFF',
  lightAccent: '#F6BE48',
  darkAccent: '#D08438',
  error: '#E2322A',
  textSelection: '#FF5CFF',
  transparent: 'transparent',
}

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

// typographic scale
const fontSizes = [
  '1rem', //h6
  '1rem', //h5
  '1.688rem', //h4
  '2.5rem', //h3
  '2.813rem', //h2
  '3.438rem', //h1
  '1.375rem', //CTA,LargeBody
  '1.375rem', //Body
  '1.125rem', //smallBody,breadCrumbs
  '1rem', //Links
  '1.833rem', //unique
  '0.583rem', //smallFooter
  '0.75rem', //XS smallBody,breadCrumbs
  '2.0rem', //XS h3
  '1.438rem', //3 Columns Module Text
  '1.438rem', //Desktop CTA Button
  '1rem', //Mobile CTA Button
  '1.125rem', //Mobile Body Text
  '0.813rem', //Breadcrumb
]

// for any scale, either array or objects will work
const lineHeights = [
  '3.125rem', //h1
  '2.688rem', //h2
  '2.188rem', //h3
  '1.563rem', //h4,CTA,Largebody
  '1.41rem', //h5
  '1.41rem', //h6
  '1.33rem', //smallbody,breadcrumbs,links
  '2.5rem', //Unique
  '0.5rem', //smallfooter
  '1.875rem', //3 Columns Module Text
  '1.438rem', //Desktop CTA Button
  '1rem', //Mobile CTA Button
  '1.563rem', //Link
  '1rem', //Breadcrumb
  '1.375rem', //Module Text Desktop
]

const fontWeights = {
  normal: 400,
  semiBold: 600,
  bold: 700,
}

const letterSpacings = [
  '0.172rem', //h1
  '0.098rem', //h2
  '0.088rem', //h3
  '0.059rem', //h4,CTA,Largebody,
  '0.155rem', //h5
  '0.155rem', //h6
  '0.130rem', //body,Links
  '0.155rem', //unique
  '0', //smallfooter,breadcrumbs,smallbody
  '0.043rem', //3 Columns Module Text
  '0.058rem', //Desktop CTA Button
  '0.040rem', //Mobile CTA Button
  '0.056rem', //Link
  '0.008rem', //Breadcrumb
]

// border-radius
const radii = [0, 2, 4, 8, 16, 24]
const borders = [0, '1px solid', '2px solid', '10px solid', '20px solid']
const shadows = [
  `0.35rem 0.35rem`, //box-shadow
  `0.187rem 0.187rem`, //CTA
  `0.125rem 0.125rem`, //Unique text-shadow
]
const gradients = [
  `linear-gradient(to bottom, ${colors.lightAccent}, ${colors.darkAccent} 60%) 1 100%`,
  `linear-gradient(${colors.transparent}, ${colors.primary})`,
]
const textAlign = ['center', 'left']
const borderBox = ['none', `0.1875rem solid ${colors.secondary}`]

const theme = {
  breakpoints,
  breakpointsMap,
  colors,
  space,
  fontFaces,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  borders,
  shadows,
  gradients,
  textAlign,
  borderBox,
  navHeight,
  headerSizes,
  containerWidths,
  mq,
}

export default theme
