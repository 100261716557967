import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { asyncReducer } from '@/lib/redux-async'

export const rootEpic = combineEpics()
export const rootReducer = combineReducers({
  async: asyncReducer,
})

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function
  }
}

// Redux Observable middleware
const epicMiddleware = createEpicMiddleware()

// Devtool (React Debugger)
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
/* eslint-enable */

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)))
epicMiddleware.run(rootEpic)

export default store
