import { css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'
import ApercuRegular from '@/assets/fonts/Apercu.otf'
import ApercuBold from '@/assets/fonts/Apercu-Bold.otf'
import MetricBold from '@/assets/fonts/Metric-Bold.otf'
import { FlowerCursorActive, FlowerCursorInActive } from '@/components/Cursor'
import theme from '@/style/theme'

export const GlobalStyles = css`
  ${emotionNormalize}
  @font-face {
    font-family: Apercu-Regular;
    src: url(${ApercuRegular});
  }
  @font-face {
    font-family: Apercu-Bold;
    src: url(${ApercuBold});
  }
  @font-face {
    font-family: Metric-Bold;
    src: url(${MetricBold});
  }

  html,
  body {
    padding: 0;
    margin: 0;
    min-height: 100%;
    font-family: ${theme.fontFaces.body};
    font-weight: 400;
    background-color: #e5e5e5;
  }

  body {
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
    ${FlowerCursorInActive}

    @media screen and (max-width: 1100px) {
      max-width: 1100px;
    }
  }

  * {
    word-break: break-word;
  }
  p {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: ${theme.fontFaces.headings};
  }

  a {
    text-decoration: none;
    font-family: ${theme.fontFaces.link};
  }

  a,
  button {
    ${FlowerCursorActive}
  }
`

export { theme }
